@keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .animated-background {
    background: linear-gradient(270deg, #6a11cb, #2575fc, #6a11cb);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
  }
  